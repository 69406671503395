import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ERROR_CONSTS, STATUS } from '../../util/constants';
import { useAlertBarStatus } from './useAlertBarStatus';

export const AlertBar = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const focusRef = useRef(null);
  let status = useAlertBarStatus();

  const vertical = 'top';
  const horizontal = 'center';
  const transition = Slide;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    focusRef?.current?.focus();
  });

  useEffect(() => {
    if (status?.length > 0 && !open) {
      setOpen(true);
    } else if (status?.length === 0 && open) {
      setOpen(false);
    }
  }, [status]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        status?.forEach((item) => {
          dispatch({
            type: item?.type,
            payload: {
              status: item?.status,
              alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
              details: '',
              data: item?.data,
            },
          });
        });
      }, 500);
    }
  }, [open]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
      >
        <div>
          {status.map((item, index) => (
            <Alert
              key={index}
              onClose={handleClose}
              role="alert"
              severity={item?.status?.toUpperCase() === STATUS.ERROR ? STATUS.ERROR.toLowerCase() : 'success'}
              style={{ display: 'flex', alignItems: 'center', padding: '1rem', marginBottom: '0.5rem' }}
            >
              {item?.alert?.MESSAGE}
            </Alert>
          ))}
        </div>
      </Snackbar>

      {children}
    </>
  );
};